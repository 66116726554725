.container {
}
.article {
  display: flex;
  padding: 1.6rem;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #dfdfdf;
  word-wrap: break-word;
  column-gap: 0.8rem;
}

.img {
  object-fit: cover;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title {
  font-size: 2rem;
  line-height: 20px;
  font-weight: 900;
   overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.brief {
  margin-top: 1rem;
  font-size: 1.4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  letter-spacing: 0.38px;
}

.info {
  flex: 1;
  justify-self: flex-end;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.logo {
}

.showMore {
  font-size: 1.4rem;
  background-color: #fff;
  color: var(--blush-rose);
  font-weight: 600;
  padding: 0.4rem 1.2rem;
  width: fit-content;
  border: 1px solid var(--blush-rose);
  border-radius: 4px;
}

@media (max-width: 768px) {
  .article {
    column-gap: 1.6rem;
  }
  .title {
    font-size: 1.8rem;
    line-height: 18px;
    max-height: 36px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;
  }
  .img {
    width: 100px;
  }
  .brief {
    font-size: 1.2rem;
    line-height: 14px;
  }
}
