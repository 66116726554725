.wrapper {
  display: flex;
  gap: 16px;
  position: relative;
  align-items: center;
}
.searchIcon {
  margin-top: 1rem;
  align-self: flex-start;
}
.filterContainer{
display: inline-block;
  width: 100%;
  padding: 3rem;
  flex: 1;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.3);
  height: 160px;

}

.input {
  padding: 10px 12px;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  font-size: 24px;
  color: var(--gray-light);
  font-weight: 600;
  background: var(--white);
  height: 48px;
  width: 100%;
  font-family: 'pauza';
}

.input::placeholder {
  font-family: var(--primary-font);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  font-weight: 600;
  display: block;
}
.hide{
  display: inherit;
}

@media (max-width: 768px) {

.hide{
  display: none;
}
  .wrapper {
    gap: 8px;
  }
  .input {
    font-size: 18px;
    height: unset;
    padding: 8px 12px;
  }
  .input::placeholder {
    font-size: 18px;
  }
  .searchIcon {
    margin-top: unset;
    align-self: unset;
  }
  .filterContainer{
    padding: 1.6rem;
    max-height: 92px;
    overflow: visible;
  }
}
