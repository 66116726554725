.container{
  font-family: var(--primary-font);

}
.form{
  display: flex;
  flex-direction: column;
}
.name,.email,.message{
border-radius: 4px;
padding: 1rem 1.5rem;
  font-size: 2rem;

}
.name,.email{
  height: 4.8rem;
}
.name::placeholder,
.email::placeholder,
.message::placeholder{
color: var(--gray-light);
font-size: 2.4rem;
font-family: var(--primary-font);
}


.email{
  margin: 1.2rem 0 2rem 0;
}
.message{
  height: 102px;
  font-weight: 400;
  resize: none;
}
