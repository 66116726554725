/* @import '@radix-ui/colors/black-alpha.css';
@import '@radix-ui/colors/mauve.css';
@import '@radix-ui/colors/violet.css'; */

/* reset */

.AccordionRoot {
  border-radius: 4px;
  background: var(--white);
  align-self: flex-start;
  position: relative;
  flex: 1;
  z-index: 1;
}

.AccordionItem {
  color: var(--charcoal-gray);
  overflow: hidden;

}

.AccordionItem:first-child {
  margin-top: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.AccordionItem:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.AccordionItem:focus-within {
  position: relative;
  box-shadow: 0 0 0 2px var(--mauve-12);
}

.AccordionHeader {
  display: flex;
}

.AccordionTrigger {
    color: var(--charcoal-gray);
  font-size: 24px;
  background-color: transparent;
  font-weight: 600;
  padding-right: 12px;
  padding-left: 12px;
  height: 48px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 27.79px;
  text-align: right;
  font-weight: 600;
  font-family: 'pauza';
}

.AccordionContent {
  overflow: hidden;
  font-size: 20px;
  background-color: inherit;
}
.AccordionContent[data-state='open'] {
  animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
}
.AccordionContent[data-state='closed'] {
  animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

.AccordionContentTextInner {
  padding-top: 15px;
  padding-right: 12px;
  padding-left: 12px;
  padding-bottom: 12px;
}

.AccordionChevron {
  color: var(--violet-10);
  transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
}
.AccordionTrigger[data-state='open'] > .AccordionChevron {
  transform: rotate(180deg);
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}

hr {
  border: none;
  height: 1px;
  background-color: var(--gray);
}

@media (max-width: 768px) {
  .AccordionTrigger {
    font-size: 18px;
    height: unset;
    padding: 8px 12px;
    line-height: unset;
  }
}
