.link {
  display: block;
  position: relative;
  width: 100%;
  background-color: #fff;
  height: 100%;
  padding: 2.5rem 1.5rem 1.5rem 1.5rem;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
}
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .container {
    padding: unset;
  }
  .link {
    height: 100%;
    padding: 16px;
  }
}
