.container {
  padding: 0.6rem 1rem;
  color: #fff;
  border-radius: 24px;
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}

.text {
  display: block;
  font-weight: 600;
}
