.container{
  display: flex;
  justify-content: space-between;
}
.iconsList{
  display: flex;
  column-gap: 1.6rem;
}


