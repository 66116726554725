.wrapper{
  flex:1;
}
.container {
  position: relative;
  padding: 3.5rem 2.5rem 1.5rem 2.5rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 4px;
  justify-content: space-between;
  height: 100%;
}
.image {
  border-radius: 4px;
  top: 0;
  left: 0;
  z-index: -1;
}
.big_text {
  color: #fff;
  font-size: 2.6rem;
  line-height: 30px;
  font-weight: 700;
  margin-bottom: 1rem;
   overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.bottom_small_text {
  color: #fff;
  font-size: 1.8rem;
  font-weight: 400;
}

.bottom {
  justify-content: space-between;
  display: flex;
}

.bottom_link {
  border-radius: 4px;
  border: 1px solid var(--blush-rose);
  background: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.7rem 1.4rem;
  font-size: 1.4rem;
  margin-top: 0.5rem;
  font-weight: 600;
  color: var(--blush-rose);
  align-self: flex-end;
}

@media (max-width: 768px) {
  .container {
    padding: 1.6rem;
  }
  .bottom_small_text {
    font-size: 1.6rem;
  }
  .bottom_link {
    margin-top: unset;
  }
  .bottom {
    flex-direction: column;
    row-gap: 2rem;
  }
  .big_text {
    margin-bottom: 0.8rem;
    line-height: 22px;
    max-height: 44px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;
  }
}
