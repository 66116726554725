.container{
margin-top: 12.5rem;
position: relative;
display: flex;
flex-direction: column;
row-gap: 3.5rem;
padding: 7rem 0;

}

.content{

  display: grid;
grid-template-columns: 1fr 1fr;
column-gap: 7rem;

}
.image{
    width: 100vw !important;
  height: 100% !important;
    right: -12.5% !important;
z-index: -1;
}
.actions{
  width: 100%;
  display: flex;
  grid-template-columns: 1fr 1fr;
  column-gap: 7rem;

}
.linksWrapper,.buttonWrapper{
  flex: 1;
}


@media (max-width:768px){
  .container{
    margin-top: 10rem;
padding: 5rem 0;
  }
  .content{
    display: flex;
    flex-direction: column;
    row-gap: 3.5rem;
    column-gap: 0;
  }
  .container::before{
    left: -2.5%;
  }
  .actions{
    flex-direction: row-reverse;
    column-gap: 0;
    justify-content: space-around;
  }
}

