.container{
    color: #fff;
  font-weight: 400;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 3.5rem;


}
.title{
  font-size: 3.6rem;
}
.text{
  font-size: 2.4rem;
  flex: 1;
}
.block{
  display: block;
}

.linksWrapper{
  width: 100%;
  align-self: flex-end;
}

@media (max-width:768px){
  .linksWrapper{
    display: none;
  }

}
