.imgContainer{
  position: relative;
  height: 10rem;
}
.imgContainer > img {
  width: 100%;
  object-fit: contain;
}

.textContainer {
  flex: 1;
}
.textContainer > span {
  font-size: 1.2rem;
  letter-spacing: 0.066px;
  color: var(--rose-pink);
  font-weight: 600;
}
.textContainer > p {
  display:block;
  margin-top: 1.5rem;
  font-size: 20px;
  font-weight: 900;
}
.tagsContainer {
  margin-top: 1rem;
  font-size: 1.2rem;
}
.quoteContainer {
  height: 11.2rem;
  font-size: 1.4rem;
  margin: 1.6rem 0;
  line-height: 16px;
  font-weight: 400;
  border-radius: 4px;
  background-color: var(--pearl-white);
  padding: 1.5rem;
  width: 100%;
  position: relative;
}
.infoBoxContainer > div {
  color: #fff;
  font-size: 1.4rem;
  font-weight: 600;
  padding: 0.8rem 1.2rem;
  background-color: var(--blush-rose);
  width: fit-content;
  border-radius: 4px;
  align-self: flex-end;
}

@media (max-width: 768px) {
  .quoteContainer {
    padding: 1.5rem 0.8rem;
    width: unset;
    margin-top: unset;
    margin-bottom: 16px;
  }
  .tagsContainer{
    margin-bottom: 20px;
  }
}
