.ribbon {
  position: absolute;
  border-radius: 24px;
  background-color: var(--cotton-candy-pink);
  color: var(--blush-rose);
  display: flex;
  top: -2.5%;
  right: 2.5%;
  column-gap: 0.8rem;
  padding: 0.3rem 1rem;
  align-items: center;
}

.text {
  text-align: center;
  font-size: 1.2rem;
}
