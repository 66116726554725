.article_news_title {
  font-size: 3.6rem;
  font-weight: 600;
  color: var(--blush-rose);
  text-align: center;
}

.article_news {
  display: flex;
  flex-direction: column;
  row-gap: 2.5rem;
  flex: 1;
}
.article_news_container {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.article_section {
  display: flex;
  column-gap: 1rem;
  margin-top: 8%;
}

@media (max-width: 768px) {
  .article_section {
    flex-direction: column;
    column-gap: unset;
    row-gap: 1.6rem;
    margin-top: 0;
  }
  .article_news_title {
    font-size: 2.4rem;
  }
  .article_news_container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .article_news {
    height: 154px;
    row-gap: 3.5rem;
    margin-top: 3.5rem;
  }
  .article_news_container > img {
    width: 20px;
    height: 18px;
  }
}
