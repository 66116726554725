.button{
    background-color: var(--pale-gold);
    font-family: var(--primary-font);
font-size: 2.4rem;
line-height: 16px;
padding: 1.5rem ;
font-weight: 400;
color: var(--charcoal-gray);
border-radius: 4px;
white-space: nowrap;
width: fit-content;
}

