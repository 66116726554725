.icon{
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  background-color: var(--pale-gold);
  border-radius: 50%;
  cursor: pointer;
}