.hero {
  position: relative;
  color: #fff;
  width: 100%;

}
.hero_content {
  width: 100%;
  padding: 4% 0 10% 0;
    display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  row-gap: 4%;

}
.hero_img{
  position:absolute;
  z-index: -1;
  height:100%;
    width: 100vw !important;
    top: -10px !important;
right: -12.5% !important;
}

.searchBarContainer {
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 48px 0;
}
.titleContainer {
  width: 100%;
  margin-top: 24px;
}
.intro {
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: 0.38px;
}

.title {
  font-size: 5.5rem;
  white-space: pre-line;
  font-weight: 700;
}

.subTitle {
  font-size: 36px;
  text-align: center;
  font-weight: 500;
}

.bold {
  font-size: 2.6rem;
  font-weight: 700;
}
  .intro > * {
    display: block;
  }

@media (max-width: 768px) {
  .intro {
    line-height: 14px;
    padding: 0 20px;
  }

  .bold,
  .intro > * {
    font-family: var(--primary-font-light-400);
    font-size: 1.4rem;
    display: inline;
  }
  .hero_img {
    right:-2.5% !important;
    width:100%
  }
  .title {
    margin-bottom: 24px;
    margin-top: 10px;
    font-size: 2.4rem;
    padding: 0 20px;
  }
  .subTitle {
    font-size: 20px;
  }
  .searchBarContainer {
    flex-direction: column;
    margin: 24px 0 48px 0;
  }
  .hero_content {
    padding: 24px 0 120px 0;
  }
  .bold {
    font-weight: 400;
  }
  .titleContainer {
  margin-top: 8px;
}
}
