.container {
}

.quoteIcon {
  position: absolute;
}

.text {
  white-space: pre-line;
  display: flex;
  padding-right: 2.5rem;
  overflow: hidden;
  height: 100%;
  text-overflow: ellipsis;
  flex-direction: column;
  row-gap: 1rem;
}

@media (max-width: 768px) {
  .container {
  }
  .text {
  }
}
