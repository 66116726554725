.home {
  padding-bottom: 9%;
}

.listContainer {
  margin-top: -5%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.listContainer > ul {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 1rem;
  row-gap: 1.5rem;
}

.listContainer > ul > li {
  flex: 1;
  width: 100%;
}

.article_news_title {
  font-size: 3.6rem;
  font-weight: 600;
  color: var(--blush-rose);
  text-align: center;
}

.article_news {
  display: flex;
  flex-direction: column;
  row-gap: 2.5rem;
  flex: 1;
}
.article_news_container {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.article_section {
  display: flex;
  column-gap: 1rem;
  margin-top: 8%;
}


.mobileView {
  display: none; /* Hide mobile view by default */
}

.desktopView {
  display: block; /* Show desktop view by default */
}

/* Small devices (phones, 600px and down) */
@media only screen and (max-width: 768px) {
  .mobileView {
    display: block; /* Show mobile view */
  }

  .desktopView {
    display: none; /* Hide desktop view */
  }
}
