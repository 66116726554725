.list {
  display: flex;
  column-gap: 0.5rem;
}
.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
