.container {

  width: 100%;
  height: 100%;
}
.title {
  color: var(--white);
  text-align: right;
  font-size: 30px;
  letter-spacing: 0.38px;
  font-weight: 700;
  margin-bottom: 16px;
}

@media (max-width: 768px) {
  .container {
    width: 100%;
    max-height: 95px;
  }
  .title {
    font-size: 1.8rem;
    margin-bottom: 8px;
  }
}
