.mobileView {
  display: none; /* Hide mobile view by default */
}

@media only screen and (max-width: 768px) {
  .mobileView {
    display: block; /* Show mobile view */
  }

  .desktopView {
    display: none; /* Hide desktop view */
  }
}